function activateEventInformationSlidedownContainer(){
  $(".event-information-slidedown-container").hide();
  $("a.event-information-slidedown" ).click(function() {
    $(this).next(".event-information-slidedown-container").slideToggle( "slow", function() {
    });
    return false;
  });
}

$(window).on("load resize", function() {
  if (window.innerWidth > 850){
    $('details.heaven--menu').attr('open', true);
  }
});

var searchTimeout;

$(document).on('turbolinks:load', function() {
  
  // Select all check boxes
  $('.checkall').click(function () {
		$(this).parents('form').find(':checkbox').not(this).prop('checked', this.checked);
	});

  // Select all Checkboxes
  $('#select-all').click(function(event) {   
    if(this.checked) {
    // Iterate each checkbox
      $(':checkbox.auto-check').each(function() {
        this.checked = true;                        
      });
    } else { 
      // Iterate each checkbox 
      $(":checkbox.auto-check").each(function() {
        this.checked = false; 
      });
    }
  });

  $('#map-container.map-cell').hide();
  
	$('tr.clickable').on('click', function() {
		var link = $(this).find('a').attr('href');
		window.location.href = link;
	});

  // Hide everything with nojs class
  $('.nojs').hide();

  $('.todo_item_done_checkbox').on('change',function(){
    $(this).closest('form.todo-items').submit();
  });

  $('span.event-filter-button').click(function(){
    $('.element--event-filter').removeClass('hide-for-small');
    $('.element--event-filter').show();
  });

  $('#custom-period-form').hide();
  $('a#custom-period-link').click(function(){
    $('#custom-period-form').slideToggle();
    return false;
  });


  function set_advertising_date() {
    el = $('#event_advertising_status');
    if(el.val() == 'sent') {
      $('#event_advertising_sent_date_1i').attr('disabled', false);
      $('#event_advertising_sent_date_2i').attr('disabled', false);
      $('#event_advertising_sent_date_3i').attr('disabled', false);
    } else {
      $('#event_advertising_sent_date_1i').attr('disabled', true);
      $('#event_advertising_sent_date_2i').attr('disabled', true);
      $('#event_advertising_sent_date_3i').attr('disabled', true);
    }
  }

  set_advertising_date();
  $('#event_advertising_status').on('change', function() {
    set_advertising_date();
  });

  function set_member_discount_input(element_id) {
    el = $(element_id);
    if(el.val() == 'fixed') {
      $('#member_discount').attr('disabled', false);
      $('#member_discount_percent').attr('disabled', true);
    } else if (el.val() == 'percent') {
      $('#member_discount_percent').attr('disabled', false);
      $('#member_discount').attr('disabled', true);
    } else if (el.val() == 'inherit') {
      $('#member_discount_percent').attr('disabled', true);
      $('#member_discount').attr('disabled', true);
    } else if (el.val() == 'none') {
      $('#member_discount_percent').attr('disabled', true);
      $('#member_discount').attr('disabled', true);
    } else if (el.val() == '') {
      $('#member_discount_percent').attr('disabled', true);
      $('#member_discount').attr('disabled', true);
    }
  }
  set_member_discount_input("#eventtype_member_discount_type");
  $('#eventtype_member_discount_type').on('change', function() {
    set_member_discount_input("#eventtype_member_discount_type");
  });
  set_member_discount_input("#event_member_discount_type");
  $('#event_member_discount_type').on('change', function() {
    set_member_discount_input("#event_member_discount_type");
  });
  set_member_discount_input("#client_member_discount_type");
  $('#client_member_discount_type').on('change', function() {
    set_member_discount_input("#client_member_discount_type");
  });

  function set_sibling_discount_input(element_id) {
    el = $(element_id);
    if(el.val() == 'fixed') {
      $('#sibling_discount').attr('disabled', false);
      $('#sibling_discount_percent').attr('disabled', true);
    } else if (el.val() == 'percent') {
      $('#sibling_discount_percent').attr('disabled', false);
      $('#sibling_discount').attr('disabled', true);
    } else if (el.val() == 'inherit') {
      $('#sibling_discount_percent').attr('disabled', true);
      $('#sibling_discount').attr('disabled', true);
    } else if (el.val() == 'none') {
      $('#sibling_discount_percent').attr('disabled', true);
      $('#sibling_discount').attr('disabled', true);
    } else if (el.val() == '') {
      $('#sibling_discount_percent').attr('disabled', true);
      $('#sibling_discount').attr('disabled', true);
    }
  }
  set_sibling_discount_input("#eventtype_sibling_discount_type");
  $('#eventtype_sibling_discount_type').on('change', function() {
    set_sibling_discount_input("#eventtype_sibling_discount_type");
  });
  set_sibling_discount_input("#event_sibling_discount_type");
  $('#event_sibling_discount_type').on('change', function() {
    set_sibling_discount_input("#event_sibling_discount_type");
  });
  set_sibling_discount_input("#client_sibling_discount_type");
  $('#client_sibling_discount_type').on('change', function() {
    set_sibling_discount_input("#client_sibling_discount_type");
  });
  function set_kits_individualization_price_input(element_id) {
    el = $(element_id);
    if(el.val() == 'offer_extra') {
      $('#kits_individualization_price_retail').attr('disabled', false);
      $('.kits_individualization_price_retail_fields').slideDown();
    } else {
      $('#kits_individualization_price_retail').attr('disabled', true);
      $('.kits_individualization_price_retail_fields').slideUp();
    }
  }


  // Kits Individualization
  function set_kits_individualization_price_input(element_id) {
    el = $(element_id);
    if(el.val() == 'offer_extra') {
      $('#kits_individualization_price_retail').attr('disabled', false);
      $('.kits_individualization_price_retail_fields').slideDown();
    } else {
      $('#kits_individualization_price_retail').attr('disabled', true);
      $('.kits_individualization_price_retail_fields').slideUp();
    }
  }
  set_kits_individualization_price_input("#select_kits_individualization");
  $('#select_kits_individualization').on('change', function() {
    set_kits_individualization_price_input("#select_kits_individualization");
  });

  $("input[name='participant[kits_individualization]']").change( function() {
    $(".offer-extra-kits-fields").slideToggle();
    //$(".kits-preview-fields").slideToggle();
    if ($("input[name='participant[kits_individualization]']").is(':checked')) {
    } else {
      $(".kit-name").val("");
      $(".kit-name").html("");
      $(".kit-number").val("");
      $(".kit-number").html("");
    }
  });

  if ($("input[name='participant[kits_individualization]']").is(':checked')) {
    $(".offer-extra-kits-fields").show();
    //$(".kits-preview-fields").show();
  } else {
    $(".offer-extra-kits-fields").hide();
    //$(".kits-preview-fields").hide();
  }

  if ($("input[name='participant[kits_individualization]']").val() == "true") {
    $(".kits-preview-fields").show();
  }


  // Hightlight the radio button wrapper div
  function radioHighlight(radioName, highlightClass) {
    var selector = "input[name='" + radioName + "']";
    $(selector + ":checked").parent().addClass(highlightClass);
    $(selector).change(function() {
      $(selector).parent().removeClass(highlightClass);
      $(selector + ":checked").parent().addClass(highlightClass);
    });
  }
  radioHighlight('event[event_session_kind]', 'radio-highlight');
  radioHighlight('disposition[status]', 'radio-highlight');
  //radioHighlight('disposition[travel_type]', 'radio-highlight');

  $('[id^=event_event_session_kind]').click(function() {
    $(this).closest("form").submit();
  });

  // Event-Filter functionality
  function empty_and_load_event_list(){
    $('#event-list').html('');
    $('#event-list').append('<div class="ajax-loader"></div>');
  }

  $(".view-type").click( function() {
    empty_and_load_event_list();
    $("#event-filter-form").submit();
  });
  $("#event-filter-button").click( function() {
    empty_and_load_event_list();
    $("#event-filter-form").submit();
  });
  // Submit Form when a checkbox gets checked
  $("input[name='eventtype_ids[]']").click(function() {
    empty_and_load_event_list();
    $("#event-filter-form").submit();
  });
  $("input[name='kids_dates[]']").click(function() {
    empty_and_load_event_list();
    $("#event-filter-form").submit();
  });
  $("input[name='goalkeeper_training']").click(function() {
    empty_and_load_event_list();
    $("#event-filter-form").submit();
  });
  $("input[name='radius']").click(function() {
    empty_and_load_event_list();
    $("#event-filter-form").submit();
  });
  $("input[name='states[]']").click(function() {
    empty_and_load_event_list();
    $("#event-filter-form").submit();
  });
    $('.event-search-field').bind('keyup', function() {
      if(searchTimeout) {
        clearTimeout(searchTimeout);
      }
      searchTimeout = setTimeout(function() {
        empty_and_load_event_list();
        $("#event-filter-form").submit();
      }, 1000);
   });


  // Birthdays
  if($("input[name='birthday_request[member]']").is(":checked")){
    $(".member-number").show();
  } else {
    $(".member-number").hide();
  }
  $("input[name='birthday_request[member]']").change( function() {
    $(".member-number").slideToggle();
  });
  // Allergien
  $(".allergies-fields").hide();
  if($("input[name='participant[allergies_activation]']").is(":checked")){
    $(".allergies-fields").show();
  } else {
    $(".allergies-fields").hide();
  }
  $("input[name='participant[allergies_activation]']").change( function() {
    $(".allergies-fields").slideToggle();
  });

  // Member
  if($("input[name='participant[member]']").is(":checked")){
    $(".member-number").show();
  } else {
    $(".member-number").hide();
  }
  $("input[name='participant[member]']").change( function() {
    $(".member-number").slideToggle();
  });

  // FCSTP Booking
  if($("input[name='participant[fcstp_club]']").is(":checked")){
    $(".fcstp_club").show();
  } else {
    $(".fcstp_club").hide();
  }
  $("input[name='participant[fcstp_club]']").change( function() {
    $(".fcstp_club").slideToggle();
  });


  $(".event-cloth-sizes-inherit").hide();
  $(".event-cloth-sizes-self").hide();

  if ($("#event_cloth_sizes_offering").val() == 'inherit') {
    $(".event-cloth-sizes-inherit").show();
  }
  if ($("#event_cloth_sizes_offering").val() == 'self') {
    $(".event-cloth-sizes-self").show();
  }
  $("#event_cloth_sizes_offering").change( function() {
    if ($(this).val() == 'inherit') {
      $(".event-cloth-sizes-inherit").slideDown();
      $(".event-cloth-sizes-self").slideUp();
    } else if ($(this).val() == 'self') {
      $(".event-cloth-sizes-inherit").slideUp();
      $(".event-cloth-sizes-self").slideDown();
    }
  });


  $(".sepa_fields").hide();
  $(".transfer_fields").hide();
  if($("#participant_payment_method_psp").is(':checked')) {
    $(".sepa_fields").hide();
    $(".transfer_fields").hide();
  }
  if($("#participant_payment_method_sepa").is(':checked')) {
    $(".sepa_fields").show();
  }
  $(".transfer_fields").hide();
  if($("#participant_payment_method_transfer").is(':checked')) {
    $(".transfer_fields").show();
  }
  $("#participant_payment_method_psp").change( function() {
    if ($(this).is(':checked') && $(this).val() == 'psp') {
      $(".sepa_fields").slideUp();
      $(".transfer_fields").slideUp();
    }
  });
  $("#participant_payment_method_sepa").change( function() {
    if ($(this).is(':checked') && $(this).val() == 'sepa') {
      $(".sepa_fields").slideDown();
      $(".transfer_fields").slideUp();
    }
  });
  $("#participant_payment_method_transfer").change( function() {
    if ($(this).is(':checked') && $(this).val() == 'transfer') {
      $(".sepa_fields").slideUp();
      $(".transfer_fields").slideDown();
    }
  });
  $("#participant_payment_method_cash").change( function() {
    if ($(this).is(':checked') && $(this).val() == 'cash') {
      $(".sepa_fields").slideUp();
      $(".transfer_fields").slideUp();
    }
  });
  
  // Event external partner booking
  $("#external_partner1").change(function() {
    $("#bookable").prop("checked", 1);
    $("#waiting_list").prop("checked", 1);
  });

  // Disable kits_deselect_discount_retail unless kits_offering is 
  // set to 'optional'
  if ($("#event_kits_offering").val() != 'optional') {
    $("#event_kits_deselect_discount_retail").prop('disabled', true);
  }
  if ($("#event_kits_offering").val() != 'optional_add') {
    $("#event_kits_select_price_retail").prop('disabled', true);
  }
  $("#event_kits_offering").change( function() {
    if ($(this).val() == 'optional') {
      $("#event_kits_deselect_discount_retail").prop('disabled', false);
      $("#event_kits_select_price_retail").prop('disabled', true);
    } else if ($(this).val() == 'optional_add') {
      $("#event_kits_deselect_discount_retail").prop('disabled', true);
      $("#event_kits_select_price_retail").prop('disabled', false);
    } else {
      $("#event_kits_deselect_discount_retail").prop('disabled', true);
      $("#event_kits_select_price_retail").prop('disabled', true);
    }
  });

  if ($("#participant_kits_ordered_true").is(':checked')) {
    $(".kits-fields").show();
  } else {
    $(".kits-fields").hide();
  }

  if (!$("#participant_kits_ordered_true").length) {
    $(".kits-fields").show();
  }

  $("#participant_kits_ordered_true").change( function() {
    if ($(this).is(':checked') && $(this).val() == 'true') {
      $(".kits-fields").slideDown();
    }
  });
  $("#participant_kits_ordered_false").change( function() {
    if ($(this).is(':checked') && $(this).val() == 'false') {
      $(".kits-fields").slideUp();
    }
  });

  // Change Kit-Preview
  $(".kit-name").html($("input[name='participant[kit_name]']").val());
  $(".kit-number").html($("select[name='participant[kit_number]']").val());
  $("select[name='participant[kit_number]']").change( function() {
    $(".kit-number").html("");
    $(".kit-number").html($(this).val());
  });
  $("input[name='participant[kit_name]']").change( function() {
    $(".kit-name").html("");
    $(".kit-name").html($(this).val());
  });

  $('input#participant_goalkeeper').change(function(){
    if($(this).is(":checked")) {
      $('.kit-preview').addClass("goalkeeper");
    } else {
      $('.kit-preview').removeClass("goalkeeper");
    }
  });

  // Adjust the eventdates when a new field gets added
  $("#eventdates").on('cocoon:after-insert', function(event, insertedItem, originalEvent){
    var field = insertedItem; 
    var prev_li = $("ul#eventdates li").eq(-2);
    if($("ul#eventdates li").length != 1) {
      var start_time_1i = prev_li.find('[id$="start_time_1i"]').val();
      var start_time_2i = prev_li.find('[id$="start_time_2i"]').val();
      var start_time_3i = prev_li.find('[id$="start_time_3i"]').val();
      var start_time_4i = prev_li.find('[id$="start_time_4i"]').val();
      var start_time_5i = prev_li.find('[id$="start_time_5i"]').val();
      field.find('[id$="start_time_1i"]').val(start_time_1i);
      field.find('[id$="start_time_2i"]').val(start_time_2i);
      field.find('[id$="start_time_3i"]').val(start_time_3i);
      field.find('[id$="start_time_4i"]').val(start_time_4i);
      field.find('[id$="start_time_5i"]').val(start_time_5i);
      var end_time_1i = prev_li.find('[id$="end_time_1i"]').val();
      var end_time_2i = prev_li.find('[id$="end_time_2i"]').val();
      var end_time_3i = prev_li.find('[id$="end_time_3i"]').val();
      var end_time_4i = prev_li.find('[id$="end_time_4i"]').val();
      var end_time_5i = prev_li.find('[id$="end_time_5i"]').val();
      field.find('[id$="end_time_1i"]').val(end_time_1i);
      field.find('[id$="end_time_2i"]').val(end_time_2i);
      field.find('[id$="end_time_3i"]').val(end_time_3i);
      field.find('[id$="end_time_4i"]').val(end_time_4i);
      field.find('[id$="end_time_5i"]').val(end_time_5i);
    }
  })

  activateEventInformationSlidedownContainer();

  $('.revision-information .changelog').hide();
  $('.revision-information .shortlog').click(function(){
    $(this).next('.changelog').slideToggle();
    return false;
  });

  if ($("#feedback_question_scale").val() == "f") {
    $(".custom-mc-options").show();
  } else {
    $(".custom-mc-options").hide();
  }
  $("#feedback_question_scale").change( function() {
    if ($(this).val() == 'f') {
      $(".custom-mc-options").slideDown();
    } else {
      $(".custom-mc-options").slideUp();
    }
  });

});
